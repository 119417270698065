import React from "react"
import { DefaultHeader, Components } from "../../components/elements"
import { PageCenter } from "../../components/elements/wrappers/PageCenter.jsx"
import { Col } from "react-bootstrap"

export function Template({
    data: {
        markdownRemark: {
            frontmatter: { components, details: { subtitle, title } = {} },
        },
    },
}) {
    return (
        <PageCenter>
            <DefaultHeader title={title} subtitle={subtitle} type="small" />
            <Col className="mdcontent">
                <Components
                    components={components}
                    className="md"
                    tab_type="vertical"
                />
            </Col>
        </PageCenter>
    )
}

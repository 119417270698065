import React from "react"
import { graphql } from "gatsby"
import { Page } from "../../components/elements"
import { Template } from "./Template"

export default function Main({
    data,
    data: {
        markdownRemark: {
            frontmatter: { details: { subtitle, title, crumbs } = {} },
        },
    },
}) {
    return (
        <Page title={title} description={subtitle} crumbs={crumbs}>
            <Template data={data} />
        </Page>
    )
}

export const pageQuery = graphql`
    query ($slug: String!) {
        markdownRemark(
            frontmatter: { details: { url: { slug: { eq: $slug } } } }
        ) {
            ...MdFields
        }
    }
`
